import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Welcome.css';
import liqourImage from '../assets/images/liqour.jpg'; // Import the image

function Welcome() {
  return (
    <div
      className="jumbotron"
      style={{ backgroundImage: `url(${liqourImage})` }} // Apply the image
    >
      <h1>Welcome to Barrels & Bottles<span>&#174;</span></h1>
      <div className="copyright-text">
        <p>&copy; 2024 Barrels & Bottles<span>&#174;</span>. All rights reserved.</p>
      </div>
      <Link to="/about"> {/* Add a Link to the About page */}
        <button className="about-button">Learn More</button>
      </Link>
    </div>
  );
}

export default Welcome;
