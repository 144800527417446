import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import './Products.css';

const products = [
  { name: 'Corona Extra®', description: 'Corona is a brand of beer produced by Grupo Modelo in Mexico and exported to markets around the world.', price: '₹195', rarity: 3, details: 'Corona beer, a Mexican-born brew with a distinct flavor and iconic branding, has left an indelible mark on the beer industry.' },
  { name: 'Jack Daniel’s Old No. 7®', description: 'Classic Tennessee whiskey known for its smooth, mellow flavor.', price: '₹1500', rarity: 3, details: 'Jack Daniel’s Old No. 7 is renowned for its smoothness and rich caramel notes, making it a staple in whiskey collections worldwide.' },
  { name: 'Hennessy VS®', description: 'Popular Cognac with a rich and fruity character.', price: '₹2200', rarity: 3, details: 'Hennessy VS offers a blend of ripe fruit flavors with a hint of vanilla, making it a versatile choice for cocktails or sipping neat.' },
  { name: 'Johnnie Walker Black Label®', description: 'Blended Scotch whisky with a deep, complex flavor profile.', price: '₹2000', rarity: 3, details: 'Johnnie Walker Black Label is a rich and complex blend with smoky, vanilla, and dried fruit notes, perfect for those who enjoy a bold Scotch.' },
  { name: 'Bacardi Superior®', description: 'Versatile white rum ideal for cocktails with a clean, light taste.', price: '₹1200', rarity: 3, details: 'Bacardi Superior is known for its clean, crisp flavor and is a popular choice for classic cocktails like the Mojito.' },
  { name: 'Absolut Vodka®', description: 'Swedish vodka with a pure and smooth finish.', price: '₹1500', rarity: 3, details: 'Absolut Vodka is crafted with winter wheat and has a clean, smooth taste that makes it ideal for a variety of cocktails.' },
  { name: 'Jim Beam Bourbon®', description: 'Classic Kentucky bourbon with a bold, sweet flavor.', price: '₹1400', rarity: 3, details: 'Jim Beam Bourbon features notes of vanilla, caramel, and oak, offering a quintessential American bourbon experience.' },
  { name: 'Glenfiddich 12 Year Old®', description: 'Single malt Scotch whisky with fruity and floral notes.', price: '₹3000', rarity: 4, details: 'Glenfiddich 12 Year Old is celebrated for its fresh, fruity character with hints of pear and oak, making it a popular choice for single malt enthusiasts.' },
  { name: 'Chivas Regal 12®', description: 'Smooth blended Scotch whisky with hints of honey and vanilla.', price: '₹2800', rarity: 4, details: 'Chivas Regal 12 offers a rich, smooth taste with flavors of honey, vanilla, and a touch of spice, perfect for a refined whisky experience.' },
  { name: 'Tanqueray London Dry Gin®', description: 'Gin with a robust and classic juniper flavor.', price: '₹2000', rarity: 4, details: 'Tanqueray London Dry Gin is renowned for its crisp, bold juniper flavor and balanced botanical notes, ideal for a classic gin and tonic.' },
  { name: 'Patrón Silver®', description: 'Premium tequila with a crisp, clean taste.', price: '₹3500', rarity: 5, details: 'Patrón Silver is a high-quality tequila known for its smooth, crisp flavor with a hint of citrus, making it perfect for sipping or cocktails.' },
  { name: 'Grey Goose Vodka®', description: 'French vodka known for its exceptional purity and smoothness.', price: '₹4000', rarity: 5, details: 'Grey Goose Vodka is made from French wheat and distilled in the Cognac region, offering a silky, smooth texture and subtle flavors.' },
  { name: 'Ciroc Ultra Premium Vodka®', description: 'Vodka made from grapes, offering a distinctive and smooth flavor.', price: '₹4500', rarity: 5, details: 'Ciroc Ultra Premium Vodka is crafted from French grapes, resulting in a unique, smooth vodka with hints of citrus and vanilla.' },
  { name: 'Captain Morgan Original Spiced Rum®', description: 'Spiced rum with notes of vanilla, caramel, and spices.', price: '₹1500', rarity: 3, details: 'Captain Morgan Original Spiced Rum is a flavorful rum with a rich blend of spices, ideal for cocktails or enjoying on its own.' },
  { name: 'Glenlivet 12 Year Old®', description: 'Single malt Scotch with a rich, fruity character and smooth finish.', price: '₹3200', rarity: 4, details: 'Glenlivet 12 Year Old is known for its smooth, fruity flavors with a hint of oak, making it a beloved choice among single malt Scotch lovers.' },
  { name: 'Maker’s Mark Bourbon®', description: 'Kentucky bourbon with a smooth and sweet profile.', price: '₹2000', rarity: 4, details: 'Maker’s Mark Bourbon features a distinctive sweetness with hints of vanilla and caramel, crafted using red winter wheat for a smooth finish.' },
  { name: 'Wild Turkey 101®', description: 'High-proof bourbon with a rich, full-bodied flavor.', price: '₹2500', rarity: 4, details: 'Wild Turkey 101 is a bold bourbon with a high proof, offering intense flavors of caramel, spice, and oak for a robust drinking experience.' },
  { name: 'Dewar’s White Label®', description: 'Blended Scotch whisky with a smooth and mellow taste.', price: '₹1800', rarity: 3, details: 'Dewar’s White Label is a well-balanced blend with smooth, honeyed flavors and a hint of smoke, making it a versatile choice for many occasions.' },
  { name: 'Jameson Irish Whiskey®', description: 'Triple-distilled Irish whiskey with a smooth, gentle flavor.', price: '₹2000', rarity: 3, details: 'Jameson Irish Whiskey is known for its smoothness and subtle notes of vanilla and nutty oak, crafted through a triple distillation process.' },
  { name: 'Bombay Sapphire Gin®', description: 'London dry gin with a unique blend of botanicals.', price: '₹2500', rarity: 4, details: 'Bombay Sapphire Gin features a complex blend of ten botanicals, resulting in a bright, aromatic gin with a touch of citrus and spice.' },
  { name: 'Don Julio Blanco Tequila®', description: 'High-quality tequila with a fresh, crisp taste.', price: '₹3800', rarity: 5, details: 'Don Julio Blanco is a premium tequila with a bright, clean flavor profile and subtle notes of citrus and agave, perfect for sipping or cocktails.' },
  { name: 'Suntory Hibiki Harmony®', description: 'Japanese blended whisky with a harmonious and elegant flavor.', price: '₹5000', rarity: 5, details: 'Suntory Hibiki Harmony is a masterfully blended whisky with a delicate balance of sweet, fruity, and spicy notes, reflecting the artistry of Japanese whisky making.' },
  { name: 'Oban 14 Year Old®', description: 'Single malt Scotch with a balanced mix of smoky and sweet notes.', price: '₹4500', rarity: 5, details: 'Oban 14 Year Old offers a harmonious combination of smoke, sweetness, and fruitiness, making it a well-rounded single malt Scotch.' },
  { name: 'The GlenDronach 12 Year Old®', description: 'Rich, sherried Scotch whisky with notes of dried fruit and spice.', price: '₹4200', rarity: 4, details: 'The GlenDronach 12 Year Old is known for its rich sherry influence, offering deep flavors of dried fruit, nuts, and spices with a smooth finish.' },
  { name: 'Johnnie Walker Red Label®', description: 'Bold and versatile blended Scotch whisky with a smoky flavor.', price: '₹1800', rarity: 2, details: 'Johnnie Walker Red Label features bold, smoky flavors with a hint of spice, making it a versatile whisky for mixing or enjoying neat.' },
  { name: 'Belvedere Vodka®', description: 'Polish vodka with a smooth, velvety texture.', price: '₹3000', rarity: 4, details: 'Belvedere Vodka is made from Polish rye, offering a smooth, creamy texture with subtle vanilla and spice notes, ideal for high-quality cocktails.' },
  { name: 'Michter’s Bourbon®', description: 'Small-batch bourbon with a rich, complex flavor.', price: '₹3200', rarity: 4, details: 'Michter’s Bourbon features a full-bodied flavor profile with rich notes of caramel, vanilla, and spice, crafted in small batches for quality and consistency.' },
  { name: 'Aberlour 12 Year Old®', description: 'Speyside single malt with a rich, sherried flavor profile.', price: '₹3500', rarity: 4, details: 'Aberlour 12 Year Old offers a rich, sherried character with flavors of dried fruit, chocolate, and spices, known for its smooth, warming finish.' },
  { name: 'Kahlúa®', description: 'Coffee liqueur with rich, sweet coffee flavor.', price: '₹1800', rarity: 2, details: 'Kahlúa is a rich coffee liqueur with deep coffee and vanilla notes, perfect for adding a touch of sweetness and complexity to cocktails and desserts.' },
  { name: 'Grand Marnier®', description: 'Orange liqueur with a blend of cognac and orange essence.', price: '₹2500', rarity: 3, details: 'Grand Marnier combines cognac with orange essence for a rich, complex liqueur with flavors of orange, vanilla, and oak, ideal for sipping or mixing.' },
  { name: 'Patrón Añejo®', description: 'Aged tequila with complex flavors of vanilla, oak, and spice.', price: '₹6000', rarity: 5, details: 'Patrón Añejo is aged in oak barrels, resulting in a sophisticated tequila with rich notes of vanilla, caramel, and spice, perfect for sipping straight.' },
  { name: 'Elijah Craig Small Batch®', description: 'Bourbon with rich, spicy flavors and a smooth finish.', price: '₹3000', rarity: 4, details: 'Elijah Craig Small Batch offers a complex flavor profile with notes of caramel, spice, and oak, known for its smooth, well-balanced finish.' },
  { name: 'Four Roses Single Barrel®', description: 'Full-bodied bourbon with a rich, complex taste.', price: '₹3500', rarity: 4, details: 'Four Roses Single Barrel is celebrated for its rich, full-bodied flavor with notes of spice, fruit, and vanilla, making it a favorite among bourbon enthusiasts.' },
  { name: 'Hibiki 17 Year Old®', description: 'Japanese blended whisky with a rich, layered flavor profile.', price: '₹8000', rarity: 5, details: 'Hibiki 17 Year Old is a luxurious blend with complex layers of fruit, honey, and spices, reflecting the depth and craftsmanship of Japanese whisky.' },
  { name: 'Laphroaig 10 Year Old®', description: 'Peaty and smoky Scotch with a bold flavor.', price: '₹4000', rarity: 4, details: 'Laphroaig 10 Year Old is known for its intense peat smoke and medicinal qualities, offering a bold and distinctive Scotch experience.' },
  { name: 'Courvoisier VSOP®', description: 'Cognac with a refined taste and hints of fruit and spice.', price: '₹3000', rarity: 4, details: 'Courvoisier VSOP features a smooth, well-rounded flavor with hints of dried fruit, vanilla, and spice, making it a versatile choice for sipping or cocktails.' },
  { name: 'Nikka From The Barrel®', description: 'Japanese whisky with a robust and intense flavor profile.', price: '₹4500', rarity: 5, details: 'Nikka From The Barrel is a powerful Japanese whisky with a rich, bold flavor profile and complex notes of fruit, spice, and oak.' },
  { name: 'Bulleit Bourbon®', description: 'Spicy and smooth bourbon with a high rye content.', price: '₹2200', rarity: 3, details: 'Bulleit Bourbon features a high rye content, resulting in a spicy, smooth bourbon with notes of vanilla, oak, and a hint of pepper.' },
  { name: 'Chandon Brut®', description: 'Sparkling wine with crisp, refreshing flavors and fine bubbles.', price: '₹2000', rarity: 3, details: 'Chandon Brut offers a crisp, refreshing taste with fine bubbles and notes of green apple, citrus, and toast, making it perfect for celebrations.' },
  { name: 'Mumm Cordon Rouge®', description: 'French Champagne with vibrant flavors and a fine mousse.', price: '₹3000', rarity: 4, details: 'Mumm Cordon Rouge Champagne features vibrant flavors of citrus and red fruits with a fine mousse, ideal for special occasions.' },
  { name: 'Cazadores Reposado®', description: 'Tequila aged in oak barrels with a smooth, mellow flavor.', price: '₹2500', rarity: 3, details: 'Cazadores Reposado is aged in oak barrels, providing a smooth, mellow flavor with hints of vanilla, caramel, and spice.' },
  { name: 'Glenmorangie Original®', description: 'Single malt Scotch with a delicate and floral character.', price: '₹3000', rarity: 3, details: 'Glenmorangie Original offers a delicate, floral character with notes of citrus and vanilla, making it a light and elegant choice for Scotch lovers.' },
  { name: 'Crown Royal®', description: 'Canadian whisky with a smooth and refined taste.', price: '₹2,500', rarity: 3, details: 'Crown Royal offers a blend of over 50 distinct whiskies, resulting in a smooth, rich flavor with hints of vanilla and oak.' },
  { name: 'Macallan 12 Year Old®', description: 'Sherry oak-aged single malt with rich, fruity notes.', price: '₹5,500', rarity: 5, details: 'Macallan 12 Year Old is matured in sherry-seasoned oak casks, providing rich, complex flavors of dried fruit, spice, and chocolate.' },
  { name: 'Baileys Irish Cream®', description: 'Cream liqueur with a blend of Irish whiskey and cream.', price: '₹1,800', rarity: 2, details: 'Baileys Irish Cream combines smooth Irish whiskey with rich cream and cocoa, perfect for adding a touch of sweetness to coffee or desserts.' },
  { name: 'Glenmorangie Lasanta®', description: '12-year-old single malt with a sherry cask finish.', price: '₹6,000', rarity: 5, details: 'Glenmorangie Lasanta offers a rich, full-bodied flavor with notes of toffee, raisins, and spice, enhanced by its sherry cask maturation.' },
  { name: 'Pernod Absinthe®', description: 'French absinthe with a bold anise flavor.', price: '₹3,000', rarity: 4, details: 'Pernod Absinthe is crafted with wormwood and anise, offering a strong, herbal flavor that is traditionally enjoyed with water and sugar.' },
  { name: 'Tito’s Handmade Vodka®', description: 'American vodka with a smooth, clean taste.', price: '₹2,000', rarity: 3, details: 'Tito’s Handmade Vodka is distilled from corn, resulting in a smooth, clean vodka with a slightly sweet flavor, perfect for cocktails.' },
  { name: 'Courvoisier VS®', description: 'Young Cognac with a smooth and fruity character.', price: '₹2,200', rarity: 3, details: 'Courvoisier VS offers a balanced blend of fresh fruit and subtle oak flavors, making it an approachable Cognac for any occasion.' },
  { name: 'Woodford Reserve Bourbon®', description: 'Kentucky straight bourbon with rich, complex flavors.', price: '₹3,500', rarity: 4, details: 'Woodford Reserve Bourbon is known for its rich, full-bodied taste with notes of dried fruit, spice, and vanilla, crafted in small batches.' },
  { name: 'Moët & Chandon Imperial Brut®', description: 'Classic French Champagne with elegant, fruity notes.', price: '₹6,000', rarity: 4, details: 'Moët & Chandon Imperial Brut offers a fine balance of fruitiness and freshness, with hints of apple, pear, and citrus, perfect for celebrations.' },
  { name: 'Remy Martin VSOP®', description: 'Well-balanced Cognac with a smooth, fruity flavor.', price: '₹3,800', rarity: 4, details: 'Remy Martin VSOP is crafted from fine Champagne grapes, offering a smooth, fruity flavor with notes of apricot, vanilla, and oak.' },
  { name: 'Lagavulin 16 Year Old®', description: 'Peaty single malt Scotch with a bold, smoky character.', price: '₹6,500', rarity: 5, details: 'Lagavulin 16 Year Old is known for its intense peat smoke and rich, complex flavors of dried fruit, spice, and oak, ideal for Scotch lovers.' },
  { name: 'Jose Cuervo Tradicional Reposado®', description: 'Tequila aged in oak for a smooth, balanced flavor.', price: '₹2,500', rarity: 3, details: 'Jose Cuervo Tradicional Reposado is aged in oak barrels, offering a smooth, well-balanced flavor with hints of agave, vanilla, and spice.' },
  { name: 'Buffalo Trace Bourbon®', description: 'Classic bourbon with a smooth, rich taste.', price: '₹2,200', rarity: 3, details: 'Buffalo Trace Bourbon features a smooth, rich flavor with notes of caramel, vanilla, and oak, crafted in small batches for quality.' },
  { name: 'Chivas Regal 18®', description: 'Premium blended Scotch with a rich, complex flavor.', price: '₹6,000', rarity: 4, details: 'Chivas Regal 18 is a luxurious blend with rich, complex flavors of dried fruit, chocolate, and spice, crafted for a refined drinking experience.' },
  { name: 'Pimm’s No. 1®', description: 'British gin-based liqueur with a fruity, herbal taste.', price: '₹1,800', rarity: 3, details: 'Pimm’s No. 1 is a refreshing liqueur with a blend of gin, herbs, and spices, typically enjoyed in cocktails with lemonade and fresh fruit.' },
  { name: 'Glenfarclas 12 Year Old®', description: 'Sherried single malt Scotch with a rich, full flavor.', price: '₹4,500', rarity: 4, details: 'Glenfarclas 12 Year Old offers a rich, full-bodied flavor with notes of dried fruit, spice, and chocolate, thanks to its sherry cask maturation.' },
  { name: 'Martell Cordon Bleu®', description: 'Iconic Cognac with a rich, complex flavor.', price: '₹9,000', rarity: 5, details: 'Martell Cordon Bleu is celebrated for its rich, complex flavor profile with notes of dried fruit, spice, and oak, making it a luxurious Cognac choice.' },
  { name: 'Ardbeg 10 Year Old®', description: 'Islay single malt with intense peat smoke and citrus notes.', price: '₹5,000', rarity: 4, details: 'Ardbeg 10 Year Old is known for its powerful peat smoke and bold flavors of citrus, vanilla, and spice, offering a true Islay Scotch experience.' },
  { name: 'Grand Marnier Cordon Rouge®', description: 'Orange liqueur with a blend of Cognac and orange essence.', price: '₹3,000', rarity: 4, details: 'Grand Marnier Cordon Rouge combines fine Cognac with orange essence, offering a rich, complex liqueur perfect for sipping or cocktails.' },
  { name: 'Highland Park 12 Year Old®', description: 'Orkney single malt with a balanced, smoky sweetness.', price: '₹4,800', rarity: 4, details: 'Highland Park 12 Year Old offers a harmonious blend of smoky, sweet, and fruity flavors, with notes of honey, peat, and spice.' },
  { name: 'Tullamore D.E.W. Original®', description: 'Smooth Irish whiskey with a mellow, balanced taste.', price: '₹2,000', rarity: 3, details: 'Tullamore D.E.W. Original is triple distilled for smoothness, offering a mellow, balanced flavor with notes of citrus, apple, and spice.' },
  { name: 'Cazadores Blanco®', description: 'Tequila with a crisp, clean flavor and hint of agave.', price: '₹2,200', rarity: 3, details: 'Cazadores Blanco is known for its fresh, crisp flavor with a hint of sweet agave, making it an excellent choice for cocktails or sipping neat.' },
  { name: 'Monkey Shoulder®', description: 'Blended malt Scotch with a smooth, rich character.', price: '₹3,000', rarity: 4, details: 'Monkey Shoulder is a smooth, rich blended malt with flavors of vanilla, spice, and citrus, making it versatile for cocktails or sipping neat.' },
  { name: 'Bénédictine D.O.M.®', description: 'Herbal liqueur with a complex, rich flavor profile.', price: '₹3,500', rarity: 4, details: 'Bénédictine D.O.M. is a unique herbal liqueur with a blend of 27 herbs and spices, offering a rich, complex flavor with hints of honey and spice.' },
  { name: 'Mount Gay Eclipse Rum®', description: 'Barbadian rum with a smooth, balanced taste.', price: '₹1,800', rarity: 3, details: 'Mount Gay Eclipse Rum is known for its smooth, balanced flavor with notes of vanilla, banana, and spice, ideal for cocktails or sipping.' },
  { name: 'The Balvenie DoubleWood 12 Year Old®', description: 'Single malt Scotch with a sherry cask finish.', price: '₹5,200', rarity: 4, details: 'The Balvenie DoubleWood 12 Year Old is aged in both American oak and sherry casks, offering rich flavors of fruit, spice, and oak.' },
  { name: 'Glenlivet Founder’s Reserve®', description: 'Smooth single malt Scotch with a fruity, creamy character.', price: '₹3,200', rarity: 3, details: 'Glenlivet Founder’s Reserve is a smooth, easy-drinking single malt with flavors of citrus, toffee, and cream, inspired by the distillery’s original recipes.' },
  { name: 'Kraken Black Spiced Rum®', description: 'Rich, dark spiced rum with bold flavors.', price: '₹2,000', rarity: 3, details: 'Kraken Black Spiced Rum offers bold, rich flavors of caramel, vanilla, and spice, perfect for adding depth to cocktails or enjoying on the rocks.' },
  { name: 'Patrón XO Cafe®', description: 'Tequila-based coffee liqueur with a rich, dark flavor.', price: '₹3,000', rarity: 4, details: 'Patrón XO Cafe combines Patrón Silver Tequila with coffee essence, offering a rich, dark liqueur with flavors of chocolate and vanilla.' },
  { name: 'Wild Turkey 101®', description: 'High-proof bourbon with bold, spicy flavors.', price: '₹2,400', rarity: 4, details: 'Wild Turkey 101 is known for its bold, spicy flavor with notes of caramel, vanilla, and oak, offering a robust bourbon experience.' },
  { name: 'Diplomatico Reserva Exclusiva®', description: 'Venezuelan rum with a rich, sweet profile.', price: '₹3,800', rarity: 4, details: 'Diplomatico Reserva Exclusiva is a rich, sweet Venezuelan rum with flavors of caramel, vanilla, and dried fruit, perfect for sipping.' },
  { name: 'Talisker 10 Year Old®', description: 'Isle of Skye single malt with a smoky, maritime flavor.', price: '₹4,500', rarity: 4, details: 'Talisker 10 Year Old offers a smoky, peaty flavor with hints of sea salt, pepper, and citrus, embodying the rugged spirit of the Isle of Skye.' },
  { name: 'Eagle Rare 10 Year Old®', description: 'Smooth, rich bourbon with a complex flavor profile.', price: '₹4,000', rarity: 4, details: 'Eagle Rare 10 Year Old is a smooth, rich bourbon with complex flavors of toffee, orange peel, and leather, offering a refined drinking experience.' },
  { name: 'Hennessy Black®', description: 'Smooth, versatile Cognac with a modern twist.', price: '₹3,500', rarity: 4, details: 'Hennessy Black offers a smooth, versatile flavor profile with notes of citrus, honey, and spice, ideal for mixing in cocktails or sipping.' },
  { name: 'Havana Club 7 Year Old®', description: 'Cuban rum with rich, layered flavors.', price: '₹3,200', rarity: 4, details: 'Havana Club 7 Year Old is a rich, complex Cuban rum with flavors of tropical fruit, vanilla, and caramel, perfect for sipping or cocktails.' },
  { name: 'Bruichladdich The Classic Laddie®', description: 'Unpeated Islay single malt with a floral, fruity character.', price: '₹4,800', rarity: 4, details: 'Bruichladdich The Classic Laddie offers a fresh, floral, and fruity flavor profile, representing the unpeated style of Islay whisky.' },
  { name: 'Amrut Fusion®', description: 'Indian single malt whisky with a rich, complex flavor.', price: '₹4,200', rarity: 4, details: 'Amrut Fusion is a unique Indian single malt made from a blend of Indian and Scottish barley, offering a rich, complex flavor with notes of fruit, spice, and oak.' },
  { name: 'Bacardi Reserva Ocho®', description: 'Aged rum with a rich, smooth taste.', price: '₹3,000', rarity: 4, details: 'Bacardi Reserva Ocho is aged for eight years, offering a rich, smooth flavor with notes of dried fruit, spice, and caramel, ideal for sipping or cocktails.' },
  { name: 'Redbreast 12 Year Old®', description: 'Irish pot still whiskey with a rich, sherried flavor.', price: '₹5,000', rarity: 4, details: 'Redbreast 12 Year Old is known for its rich, sherried flavor profile with notes of dried fruit, spice, and toasted oak, crafted in the traditional pot still style.' },
  { name: 'Bulleit Rye®', description: 'Rye whiskey with a bold, spicy character.', price: '₹2,400', rarity: 4, details: 'Bulleit Rye is crafted with a high rye content, offering a bold, spicy flavor with notes of vanilla, honey, and oak, ideal for cocktails or sipping.' },
  { name: 'Hine Rare VSOP®', description: 'Elegant Cognac with a smooth, fruity flavor.', price: '₹4,500', rarity: 4, details: 'Hine Rare VSOP is an elegant Cognac with a smooth, fruity flavor profile featuring notes of apricot, vanilla, and spice, ideal for sipping neat.' },
  { name: 'Michter’s US*1 Bourbon®', description: 'Small batch bourbon with a rich, mellow flavor.', price: '₹4,000', rarity: 4, details: 'Michter’s US*1 Bourbon is known for its rich, mellow flavor with notes of caramel, vanilla, and stone fruit, crafted in small batches for quality.' },
  { name: 'Pierre Ferrand Ambre®', description: 'Cognac with a light, fruity, and floral character.', price: '₹3,500', rarity: 4, details: 'Pierre Ferrand Ambre offers a light, fruity, and floral flavor profile with notes of apricot, honey, and oak, perfect for those who prefer a more delicate Cognac.' },
  { name: 'Old Pulteney 12 Year Old®', description: 'Highland single malt with a maritime, salty character.', price: '₹4,200', rarity: 4, details: 'Old Pulteney 12 Year Old is known for its distinctive maritime character, with flavors of sea salt, citrus, and spice, reflecting its coastal origins.' },
  { name: 'Zacapa 23 Centenario®', description: 'Premium Guatemalan rum with rich, layered flavors.', price: '₹5,000', rarity: 4, details: 'Zacapa 23 Centenario is a luxurious Guatemalan rum with rich, layered flavors of dried fruit, vanilla, and honey, aged in a solera system.' },
  { name: 'Yamazaki 12 Year Old®', description: 'Japanese single malt with a delicate, fruity character.', price: '₹7,500', rarity: 4, details: 'Yamazaki 12 Year Old is known for its delicate, fruity flavor profile with notes of peach, pineapple, and vanilla, representing the elegance of Japanese whisky.' },
  { name: 'Rittenhouse Rye®', description: 'Bold, spicy rye whiskey with a rich flavor.', price: '₹2,800', rarity: 4, details: 'Rittenhouse Rye is a bold, spicy rye whiskey with rich flavors of cinnamon, nutmeg, and vanilla, making it a popular choice for classic cocktails.' },
  { name: 'Auchentoshan Three Wood®', description: 'Lowland single malt matured in three types of casks.', price: '₹5,500', rarity: 4, details: 'Auchentoshan Three Wood is matured in bourbon, Oloroso, and Pedro Ximénez casks, offering rich, complex flavors of toffee, fruit, and spice.' },
  { name: 'Maker’s Mark 46®', description: 'Bourbon with enhanced flavors from French oak staves.', price: '₹4,000', rarity: 4, details: 'Maker’s Mark 46 is aged with French oak staves, offering enhanced flavors of caramel, vanilla, and spice, providing a richer, fuller bourbon experience.' },
  { name: 'Luksusowa Vodka®', description: 'Polish potato vodka with a smooth, creamy texture.', price: '₹2,000', rarity: 4, details: 'Luksusowa Vodka is a premium Polish vodka distilled from potatoes, offering a smooth, creamy texture and a clean, neutral flavor perfect for cocktails.' },
  { name: 'Hibiki Japanese Harmony®', description: 'Blended Japanese whisky with a balanced, floral flavor.', price: '₹6,800', rarity: 4, details: 'Hibiki Japanese Harmony offers a well-balanced blend of malt and grain whiskies, with floral and fruity notes complemented by hints of honey and oak.' },
  { name: 'Campari®', description: 'Italian bitter liqueur with a distinctive herbal flavor.', price: '₹2,000', rarity: 4, details: 'Campari is a classic Italian liqueur with a unique bitter and herbal taste, often used in cocktails like the Negroni and Americano.' },
  { name: 'Chartreuse Green®', description: 'Herbal liqueur with a complex, spicy flavor.', price: '₹3,800', rarity: 4, details: 'Chartreuse Green is a unique liqueur made from 130 herbs and spices, offering a bold, complex flavor with hints of mint, anise, and citrus.' },
  { name: 'Monkey 47 Gin®', description: 'German gin with a complex, botanical flavor.', price: '₹4,500', rarity: 4, details: 'Monkey 47 Gin is crafted with 47 different botanicals, offering a complex and aromatic flavor profile with hints of citrus, floral, and herbal notes.' },
  { name: 'Pierre Ferrand Dry Curacao®', description: 'Orange liqueur with a dry, complex flavor.', price: '₹2,800', rarity: 4, details: 'Pierre Ferrand Dry Curacao is a sophisticated orange liqueur with a dry, complex flavor profile featuring notes of bitter orange, spice, and oak.' },
  { name: 'Stolichnaya Elit Vodka®', description: 'Premium Russian vodka with a smooth, clean taste.', price: '₹5,000', rarity: 4, details: 'Stolichnaya Elit Vodka is a luxurious Russian vodka known for its ultra-smooth, clean taste, crafted using a unique freeze filtration process.' },
  { name: 'Sipsmith London Dry Gin®', description: 'Classic London dry gin with a crisp, juniper-forward flavor.', price: '₹2,800', rarity: 4, details: 'Sipsmith London Dry Gin is a traditional London dry gin with a crisp, juniper-forward flavor, complemented by citrus and spice notes.' },
  { name: 'Teeling Small Batch Irish Whiskey®', description: 'Irish whiskey with a smooth, sweet flavor.', price: '₹2,400', rarity: 4, details: 'Teeling Small Batch Irish Whiskey is known for its smooth, sweet flavor with notes of vanilla, spice, and citrus, finished in rum casks for added depth.' },
  { name: 'Hendricks Gin®', description: 'Scottish gin with a unique blend of botanicals and cucumber.', price: '₹3,200', rarity: 4, details: 'Hendricks Gin is a distinctive gin with a unique blend of botanicals, including cucumber and rose petals, offering a refreshing and aromatic flavor.' },
  { name: 'Licor 43®', description: 'Spanish liqueur with a smooth, vanilla flavor.', price: '₹2,000', rarity: 4, details: 'Licor 43 is a smooth, sweet liqueur from Spain with a rich vanilla flavor, enhanced by hints of citrus and spice, perfect for cocktails or sipping.' },
  { name: 'Plymouth Gin®', description: 'English gin with a smooth, earthy flavor.', price: '₹2,500', rarity: 4, details: 'Plymouth Gin is a smooth and balanced English gin with a slightly earthy flavor, featuring notes of juniper, coriander, and citrus, perfect for classic gin cocktails.' },
  { name: 'Bumbu Rum®', description: 'Caribbean rum with a smooth, sweet flavor.', price: '₹4,000', rarity: 4, details: 'Bumbu Rum is a premium Caribbean rum with a smooth, sweet flavor featuring notes of banana, vanilla, and caramel, ideal for sipping or cocktails.' },
  { name: 'Clase Azul Reposado®', description: 'Premium Mexican tequila with a smooth, sweet flavor.', price: '₹12,000', rarity: 4, details: 'Clase Azul Reposado is a luxurious Mexican tequila with a smooth, sweet flavor profile featuring notes of vanilla, caramel, and agave.' },
  { name: 'Espolon Blanco Tequila®', description: 'Clean, crisp tequila with vibrant flavors.', price: '₹2,500', rarity: 4, details: 'Espolon Blanco Tequila is a clean and crisp tequila with vibrant agave flavors, complemented by notes of citrus and pepper, ideal for sipping or cocktails.' },
  { name: 'Disaronno®', description: 'Italian almond liqueur with a sweet, nutty flavor.', price: '₹2,500', rarity: 4, details: 'Disaronno is a popular Italian almond liqueur with a sweet, nutty flavor, perfect for sipping neat or in cocktails like the Amaretto Sour.' },
  { name: 'Courvoisier VS®', description: 'Smooth, fruity Cognac with a rich flavor.', price: '₹3,500', rarity: 4, details: 'Courvoisier VS is a smooth and fruity Cognac with a rich flavor profile, featuring notes of ripe fruit, vanilla, and oak, ideal for sipping or cocktails.' },
  { name: 'Jameson Black Barrel®', description: 'Irish whiskey with a rich, sweet flavor.', price: '₹3,800', rarity: 4, details: 'Jameson Black Barrel is a rich and sweet Irish whiskey with notes of toffee, vanilla, and spice, aged in charred barrels for added depth and complexity.' },
  { name: 'Chartreuse Yellow®', description: 'Milder, sweeter version of the classic herbal liqueur.', price: '₹3,800', rarity: 4, details: 'Chartreuse Yellow is a milder and sweeter version of the classic herbal liqueur, offering a complex flavor with hints of honey, citrus, and spice.' },
  { name: 'Van Gogh Espresso Vodka®', description: 'Rich, coffee-flavored vodka.', price: '₹2,500', rarity: 4, details: 'Van Gogh Espresso Vodka is a rich, coffee-flavored vodka with a smooth and robust flavor, perfect for coffee lovers and ideal for cocktails.' },
  { name: 'Benedictine D.O.M.®', description: 'French herbal liqueur with a complex, sweet-spicy flavor.', price: '₹2,800', rarity: 4, details: 'Benedictine D.O.M. is a French herbal liqueur made from a blend of 27 herbs and spices, offering a complex flavor profile with sweet and spicy notes, perfect for sipping or cocktails.' },
  { name: 'Bulleit Bourbon®', description: 'Bold, spicy bourbon with a rich, smooth finish.', price: '₹3,000', rarity: 4, details: 'Bulleit Bourbon is known for its bold, spicy flavor profile with notes of vanilla, oak, and spice, offering a smooth and rich finish, perfect for sipping or in cocktails.' },
  { name: 'Sazerac Rye®', description: 'Spicy, robust rye whiskey with a hint of sweetness.', price: '₹4,200', rarity: 4, details: 'Sazerac Rye is a robust and spicy rye whiskey with a complex flavor profile that includes notes of vanilla, caramel, and a hint of sweetness, perfect for classic cocktails like the Sazerac.' },
  { name: 'Indri Trini®', description: 'Unique Indian single malt with a rich, complex flavor.', price: '₹6,500', rarity: 4, details: 'Indri Trini is a distinctive Indian single malt whiskey, aged in a combination of bourbon, sherry, and wine casks. It offers a rich and complex flavor profile with notes of dried fruit, vanilla, and spices, complemented by a smooth, mellow finish.' },
  { name: 'Paul John Brilliance®', description: 'Smooth Indian single malt with a rich flavor profile.', price: '₹4,000', rarity: 4, details: 'Paul John Brilliance is a smooth Indian single malt whiskey, crafted from the finest barley and aged in ex-bourbon barrels. It features a rich flavor profile with notes of honey, vanilla, and a hint of spice, delivering a balanced and mellow finish.' },
  { name: 'Woodburns Single Malt®', description: 'Elegant Indian single malt with a refined taste.', price: '₹3800', rarity: 4, details: 'Woodburns Single Malt is an elegant Indian whiskey, aged in American oak barrels. It offers a refined taste with notes of caramel, toffee, and a touch of oak, complemented by a smooth and satisfying finish.' },
  // Add more products as needed
];

const Products = () => {
  const [showInfo, setShowInfo] = useState(false); // For product info modal
  const [showForm, setShowForm] = useState(false); // For order form modal
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [quantity, setQuantity] = useState(1); // Default quantity
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    shipping_address: '' // Changed from 'address' to 'shipping_address'
  });

  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [sortCriteria, setSortCriteria] = useState('price'); // Default sorting by price
  const [sortDirection, setSortDirection] = useState('asc'); // Default sorting direction

  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = (product) => {
    setSelectedProduct(product);
    setShowInfo(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setQuantity(1); // Reset quantity when closing the form
  };
  
  const handleShowForm = (product) => {
    setSelectedProduct(product);
    setShowForm(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmitOrder = () => {
    const orderData = {
      ...formData,
      product_name: selectedProduct.name,
      price: selectedProduct.price,
      quantity: quantity
    };

    fetch('http://3.108.194.250/register_order.php', { // Ensure this path matches your server setup
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Ensure JSON is being sent
      },
      body: JSON.stringify(orderData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.status === 'success') {
        alert(`Order for ${selectedProduct.name} has been placed successfully!`);
        console.log(`Order for ${selectedProduct.name} has been placed successfully!`);
      } else {
        alert(`Failed to place order: ${data.message}`);
        console.error('Failed to place order. Please try again.');
      }
      handleCloseForm(); // Close the form modal
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (criteria) => {
    const direction = sortCriteria === criteria && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortCriteria(criteria);
    setSortDirection(direction);
  };

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Sorting the products based on the selected criteria and direction
  const sortedProducts = [...products].sort((a, b) => {
    const modifier = sortDirection === 'asc' ? 1 : -1;

    const parsePrice = (price) => {
      return parseFloat(price.replace(/[^\d.-]/g, '')) || 0;
    };

    if (sortCriteria === 'price') {
      const priceA = parsePrice(a.price);
      const priceB = parsePrice(b.price);
      return (priceA - priceB) * modifier;
    } else if (sortCriteria === 'rarity') {
      return (a.rarity - b.rarity) * modifier;
    }
    return 0;
  });

  // Filtering the products based on the search term
  const filteredProducts = sortedProducts.filter(product =>
    product.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="products-container">
      <Container>
        <h1>Our Products</h1>

        {/* Search bar */}
        <Form.Control
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="mb-3"
        />

        {/* Sorting options */}
        <div className="sorting-options mb-3">
          <Button variant="outline-primary" onClick={() => handleSort('price')}>
            Sort by Price {sortCriteria === 'price' ? (sortDirection === 'asc' ? '🔼' : '🔽') : ''}
          </Button>
          <Button variant="outline-primary" onClick={() => handleSort('rarity')}>
            Sort by Rarity {sortCriteria === 'rarity' ? (sortDirection === 'asc' ? '🔼' : '🔽') : ''}
          </Button>
        </div>

        <Row>
          {filteredProducts.map((product, index) => (
            <Col key={index} md={4}>
              <Card style={{ margin: '10px 0' }}>
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.description}</Card.Text>
                  <Card.Text>{product.price}</Card.Text>
                  <div className="product-actions">
                    <Button variant="primary" onClick={() => handleShowForm(product)}>Buy Now</Button>
                    <Button variant="info" onClick={() => handleShowInfo(product)} style={{ marginLeft: '10px' }}>More Info</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Modal for displaying product details */}
        {selectedProduct && (
          <Modal show={showInfo} onHide={handleCloseInfo}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedProduct.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{selectedProduct.details}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseInfo}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Modal for order form */}
        {selectedProduct && (
          <Modal show={showForm} onHide={handleCloseForm}>
            <Modal.Header closeButton>
              <Modal.Title>Place Order for {selectedProduct.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="name" 
                    value={formData.name}
                    onChange={handleFormChange}
                    placeholder="Enter your name" 
                  />
                </Form.Group>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="phone" 
                    value={formData.phone}
                    onChange={handleFormChange}
                    placeholder="Enter your phone number" 
                  />
                </Form.Group>
                <Form.Group controlId="formShippingAddress">
                  <Form.Label>Shipping Address</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="shipping_address" // Updated name from 'address' to 'shipping_address'
                    value={formData.shipping_address}
                    onChange={handleFormChange}
                    placeholder="Enter your shipping address" 
                  />
                </Form.Group>
                <Form.Group controlId="formQuantity">
                  <Form.Label>Quantity</Form.Label>
                  <div className="quantity-control">
                    <Button variant="outline-secondary" onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</Button>
                    <Form.Control
                      type="number"
                      value={quantity}
                      readOnly
                      className="quantity-input"
                    />
                    <Button variant="outline-secondary" onClick={() => setQuantity(quantity + 1)}>+</Button>
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseForm}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmitOrder}>
                Submit Order
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>

      {/* Scroll to Top Button */}
      {isVisible && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          &#8679; {/* Unicode arrow up */}
        </button>
      )}
    </div>
  );
};

export default Products;