import React from 'react';
import './App.css';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Welcome from './components/Welcome';
import Products from './components/Products';
import About from './components/About';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';

const App = () => {
  const location = useLocation(); // Get the current location

  return (
    <div>
      {/* Conditionally render the Navbar only if the current path is not '/' */}
      {location.pathname !== '/' && (
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Brand href="/">Barrels & Bottles</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/products">Products</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
      </Routes>
      <SpeedInsights />
    </div>
  );
};

// Wrap the App component with Router in a separate component
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
