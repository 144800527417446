// src/components/About.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './About.css';

const About = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://3.108.194.250/submit_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok) {
        console.log('Message submitted:', result);
        setFormData({ name: '', email: '', message: '' });
        setErrorMessage('');
        setSuccessMessage(result.message);
      } else {
        setErrorMessage(result.error || 'Failed to submit message');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error submitting message:', error);
      setErrorMessage('Failed to submit message');
      setSuccessMessage('');
    }
  };

  // Handler to navigate to the products page
  const navigateToProducts = () => {
    navigate('/products'); // Assuming "/products" is the route for the products page
  };

  return (
    <div className="about-container">
      <div className="about-section">
        <h1>About Us</h1>
        <p>Barrels & Bottles<span>&#174;</span> has been serving the community with quality beverages since [Year]. We pride ourselves on offering a wide selection of liquors, wines, and craft beers. Our knowledgeable staff is always here to help you find the perfect drink for any occasion. Whether you're looking for a rare whiskey or a fine wine, we have something for everyone.</p>
        <p>We are committed to providing exceptional service and building lasting relationships with our customers. Visit us today and explore our extensive collection of beverages. We look forward to serving you!</p>
        <button className="particulars-button" onClick={navigateToProducts}>View Particulars</button> {/* New button with CSS class */}
      </div>
      
      <div className="contact-section">
        <h1>Contact Us</h1>
        <p>If you have any questions or need assistance, please don't hesitate to contact us. You can reach us through the following methods:</p>
        <ul>
          <li><strong>Phone:</strong> (123) 456-7890</li>
          <li><strong>Email:</strong> info@barrelsandbottles.com</li>
          <li><strong>Address:</strong> 123 Beverage Lane, Drinktown, TX 12345</li>
        </ul>
        <p>Alternatively, you can fill out the contact form below and we will get back to you as soon as possible:</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
      </div>
    </div>
  );
};

export default About;
